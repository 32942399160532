import { axiosHaushalt } from "./axios";
import { PurchaseInterface } from '@/interfaces/purchase.interface';

const endpoint = "/purchase/";


export default { 
    getPurchases(): Promise<PurchaseInterface[]> {
        return new Promise((resolve, reject) => {
            return axiosHaushalt({
                method: 'GET',
                url: endpoint,
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                });
        })

    },

    createPurchase(form: any) {
        return new Promise ((resolve, reject) => {
            return axiosHaushalt({
                method: 'POST',
                url: endpoint,
                data: form
            })
                .then(response => {
                    resolve(response.data) 
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                });
        });
        
    },

    // updateArticle(id: number, form: any) {
    //     return axiosHaushalt({
    //         method: 'PUT',
    //         url: endpoint + id,
    //         data: form
    //     })
    //         .then(response => {
    //             if (response && response.data.success) {
    //                 return response.data.data;
    //             } else {
    //                 console.log("error");
    //             }
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // },
    deletePurchase(id: number) {
        return axiosHaushalt({
            method: 'DELETE',
            url: endpoint + id,
        })
            .then(response => {
                console.log(response);
                
                return response;
            })
            .catch(error => {
                console.log(error);
            });
    },
}
import { PersistentStore } from '@/store/main';
import { PurchaseInterface } from '@/interfaces/purchase.interface';
import purchaseRepository from '@/api/purchaseRepository';


interface PurchaseStoreModel extends Object {
  purchases: PurchaseInterface[],
  loading: boolean;
  submitting: boolean;
}


class PurchaseStore extends PersistentStore<PurchaseStoreModel> {
  protected data(): PurchaseStoreModel {
    return {
      purchases: [],
      loading: false,
      submitting: false,
    };
  }


  getPurchases() {
    return new Promise((resolve, reject) => {
      this.state.loading = true;
      purchaseRepository.getPurchases()
        .then((purchaseResponse:PurchaseInterface[]) => {
          this.state.purchases = purchaseResponse.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());
          resolve(purchaseResponse);
        })
        .catch((error: any) => {
          console.error(error);
          reject(error);
        })
        .finally(() => {
          this.state.loading = false;
        });
    });
  }
  createPurchase(formValues: PurchaseInterface) {
    this.state.submitting = true;
    return new Promise((resolve, reject) => {
      purchaseRepository.createPurchase(formValues)
        .then((response: any) => {
          this.state.purchases.push(response)
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        })
        .finally(() => {
          this.state.submitting = false;
        });
    });
  }
  // updateCategory(formValues: ICategoryPut) {
  //   this.state.submitting = true;
  //   toastStore.createToast(200, 'info', 'Bitte warten', 'Daten werden übermittelt', 50000, 'customDelete');
  //   return new Promise((resolve, reject) => {
  //     CategoryRepository.updateCategory(formValues)
  //       .then((response: any) => {
  //         const permissions = this.state.category!._permissions;
  //         this.state.category = response;
  //         this.state.category!._permissions = permissions;
  //         resolve(response);
  //       })
  //       .catch((error: any) => {
  //         reject(error);
  //       })
  //       .finally(() => {
  //         toastStore.deleteToast('customDelete');
  //         this.state.submitting = false;
  //       });
  //   });
  // }
  deletePurchase(id: number) {
    this.state.submitting = true;
    return new Promise((resolve, reject) => {
      purchaseRepository.deletePurchase(id)
        .then((response: any) => {
          this.state.purchases = this.state.purchases.filter((purchase: PurchaseInterface) => {
            return purchase.id !== id
          })
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        })
        .finally(() => {
          this.state.submitting = false;
        });
    });
  }
}
export const purchaseStore = new PurchaseStore('PURCHASE_STORE');
